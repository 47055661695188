import React, { useState } from 'react';
import { KTCard, KTCardBody } from '../../../_websoft/helpers';
import { ToastContainer } from 'react-toastify';
import ArtikliDropdown from '../../components/ArtikliDropdown/ArtikliDropdown';
import { useFormik } from 'formik';
import { getKarticaArtikla } from '../../api';
import notifyToast from '../../components/Toast/Toast';
import moment from 'moment';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

const KarticaArtikla: React.FC = () => {
    const [artikalId, setArtikalId] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState<any[]>([]); // Ensure it's initialized as an array

    const currentYear = new Date().getFullYear();
    const years = ['Cijela kartica', ...Array.from({ length: 5 }, (_, i) => currentYear - i)];

    const initialValues = {
        Artikal: undefined,
        godina: undefined,
    };

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setSubmitting }) => {
            setLoading(true);
            try {
                if (!artikalId) {
                    notifyToast(false, 'Molimo odaberite artikal.');
                    setLoading(false);
                    return;
                }
                const response = await getKarticaArtikla({ godina: values.godina, artikalId });
                console.log(response, 'response');

                // Assuming response.data is the array of data
                if (response.success && Array.isArray(response.data)) {
                    setData(response.data);
                    notifyToast(true, 'Success.');
                } else {
                    notifyToast(false, response.message || 'Unexpected response structure.');
                }
            } catch (error: any) {
                notifyToast(false, error.message || 'Greška prilikom dohvaćanja podataka.');
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    console.log(Data, 'Data');
    const columns = [
        { id: 'Id', header: 'Id', accessorKey: 'Id' },
        { id: 'Šifra', header: 'Šifra', accessorKey: 'artikal.ArtSifra' },
        { id: 'Artikal', header: 'Artikal', accessorKey: 'artikal.ArtNaziv' },
        { id: 'Datum', header: 'Datum', accessorFn: (row) => moment(row.Datum).format('DD-MM-YYYY') },
        { id: 'Dokument', header: 'Dokument', accessorKey: 'vrsta_dokumenta.DokNaziv' },
        { id: 'Br dok.', header: 'Br dok.', accessorKey: 'VrstaDokumentaBroj' },
        { id: 'Ulaz', header: 'Ulaz', accessorKey: 'Ulaz' },
        { id: 'Izlaz', header: 'Izlaz', accessorKey: 'Izlaz' },
        { id: 'Uposlenik', header: 'Uposlenik', accessorKey: 'uposlenik.UposlenikImePrezime' },
    ];

    const table = useReactTable({
        data: Data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody>
                {/* Conditionally render the dropdown if Data is empty */}
                {Data.length === 0 && (
                    <div className="col">
                        <label className="col-lg-4 col-form-label required fw-bold fs-6">ARTIKAL</label>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mb-3">
                                <ArtikliDropdown
                                    value={formik.values.Artikal}
                                    onChange={(name, value) => {
                                        formik.setFieldValue('Artikal', value);
                                        setArtikalId(value);
                                    }}
                                    errors={formik.errors.Artikal}
                                    touched={formik.touched.Artikal}
                                    selectName="Artikal"
                                />
                            </div>
                            <div className="col-lg-2 col-sm-6 mb-3">
                                <select
                                    className="form-control"
                                    onChange={(e) => formik.setFieldValue('godina', e.target.value)}
                                >
                                    {years.map((year, index) => (
                                        <option key={index} value={year === 'Cijela kartica' ? '' : year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-2 col-sm-6 mb-3">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={loading || formik.isSubmitting}
                                    onClick={() => formik.handleSubmit()}
                                >
                                    {!loading && 'Potvrdi'}
                                    {loading && (
                                        <span className="indicator-progress" style={{ display: 'block' }}>
                      Please wait...{' '}
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="table-responsive mt-3">
                    <div className="badge badge-light-info p-3 m-1">Kartica Artikla</div>
                    <table className="table align-middle table-bordered gy-5 no-footer">
                        <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id} className="bg-dark">
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id} className="text-white">
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody>
                        {table.getRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map((row) => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={columns.length} className="text-center">
                                    No matching records found
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </KTCardBody>
        </KTCard>
    );
};

export default KarticaArtikla;
