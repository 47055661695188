import React, {useState} from 'react'
import KategorijaRepromaterijalaDropdown from '../KategorijaRepromaterijalaDropdown/KategorijaRepromaterijalaDropdown'
import KodBojeRepromaterijalaDropdown from '../KodBojeRepromaterijalaDropdown/KodBojeRepromaterijalaDropdown'
import VrstaMaterijalaDropdown from '../VrstaMaterijalaDropdown/VrstaMaterijalaDropdown'
import KvalitetMaterijalaDropdown from '../KvalitetMaterijalaDropdown/KvalitetMaterijalaDropdown'
import ProizvodjacPartnerDropdown from '../ProizvodjacPartnerDropdown/ProizvodjacPartnerDropdown'
import DobavljacPartnerDropdown from '../DobavljacPartnerDropdown/DobavljacPartnerDropdown'
import StatusRolneDropdown from '../StatusDropdown/StatusRolneDropdown'
import SkladisteRepromaterijalaDropdown from '../SkladisteRepromaterijalaDropdown/skladisteRepromaterijalaDropdown'
import LokacijeRepromaterijalaDropdown from '../LokacijeRepromaterijalaDropdown/LokacijeRepromaterijalaDropdown'
import {useFormik} from 'formik'
import BojaDropdown from '../BojaDropdown/BojaDropdown'
import clsx from 'clsx'
import {KTSVG} from '../../../_websoft/helpers'
import {updateLokacijaRolneRepromaterijala, updateSkladisteRepromaterijala} from '../../api'
import notifyToast from '../Toast/Toast'
import StatusComponent from './StatusComponent'
import GarancijaRepromaterijalaDropdown from '../GarancijaRepromaterijalaDropdown/GarancijaRepromaterijalaDropdown'
import PovrsinskaStrukturaDropdown from '../PovrsinskaStrukturaDropdown/PovrsinskaStrukturaDropdown'
import UlazRepromaterijalaDropdown from "../UlazRepromaterijalaDropdown/UlazRepromaterijalaDropdown";
import {updateUlazRepromaterijala} from "../../api/ulaziRepromaterijals";

const initialValues = {
    ReprMatMaterijal: undefined,
    ReprMatKvalitet: undefined,
    ReprMatProizvodjac: undefined,
    ReprMatDobavljac: undefined,
    ReprMatKodBoje: undefined,
    ReprMatSkladiste: undefined,
    ReprMatKategorija: undefined,
    ReprMatStatus: undefined,
    ReprMatLokacija: undefined,
    ReprMatBojaLica: undefined,
    ReprMatId: undefined,
    ReprMatDebljina: undefined,
    ReprMatSirina: undefined,
    garancija: undefined,
    strukturaA: undefined,
}
const GetFilterRepromaterijala = ({getValues, rowSelection, updatePage, filterLoading}) => {
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            getValues(values)
        },
    })

    const [skladiste, setSkladiste] = useState()
    const [ulaz, setUlaz] = useState()
    const [lokacija, setLokacija] = useState()
    const [lokacija2, setLokacija2] = useState<string>()
    const [loading, setLoading] = useState(false)

    const onClickSkladisteUpdate = () => {

        setLoading(true)
        const idList = rowSelection.map((item) => item.ReprMatId);
        const params = {
            stavkaId: idList,
            skladiste: skladiste,
        }

        updateSkladisteRepromaterijala(params)
            .then((response) => {
                notifyToast(true, response.message)
                updatePage()
            })
            .catch((error) => notifyToast(false, error.response.data.message))
            .finally(() => setLoading(false))
    }

    const onClickUlazUpdate = () => {
        setLoading(true)
        const idList = rowSelection.map((item) => item.ReprMatId);

        console.log(idList)
        //const idList = rowSelection.map((item) => item.original.ReprMatId)
        const params = {
            stavkaId: idList,
            ulaz: ulaz,
        }

        updateUlazRepromaterijala(params)
            .then((response) => {
                notifyToast(true, response.message)
                updatePage()
            })
            .catch((error) => notifyToast(false, error.response.data.message))
            .finally(() => setLoading(false))
    }
    const onClickLokacijaeUpdate = () => {
        setLoading(true)
        const idList = rowSelection.map((item) => item.ReprMatId);
        const params = {
            stavkaId: idList,
            lokacija: lokacija,
            red: lokacija2,
        }

        updateLokacijaRolneRepromaterijala(params)
            .then((response) => {
                notifyToast(true, response.message)
                updatePage()
            })
            .catch((error) => notifyToast(false, error.response.data.message))
            .finally(() => setLoading(false))
    }

    return (
        <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
            <div className='mb-5'>
                <div
                    className='accordion-header py-3 d-flex collapsed'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_2_item_2'
                >
                      <span className='accordion-icon'>
                        <KTSVG className='svg-icon svg-icon-4' path='/media/icons/duotune/arrows/arr064.svg'/>
                      </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>IZMJENA</h3>
                </div>
                <div
                    id='kt_accordion_2_item_2'
                    className='collapse fs-6 ps-10'
                    data-bs-parent='#kt_accordion_2'
                >
                    <div className='row'>
                        <div className='col-lg-3 mb-3'>
                            <StatusComponent rowSelection={rowSelection} updatePage={updatePage}/>
                        </div>
                        <div className='col-lg-3 mb-3'>
                            <div className='row'>
                                <div className='col-lg-8 mb-3'>
                                    <UlazRepromaterijalaDropdown
                                        value={ulaz}
                                        onChange={(name, value) => setUlaz(value)}
                                        selectName='ulaz'
                                    />
                                </div>
                                <div className='col-lg-4'>
                                    <button
                                        className='btn btn-block btn-primary'
                                        onClick={onClickUlazUpdate}
                                        disabled={!rowSelection.length || !ulaz || loading}
                                    >
                                        <i className="bi bi-check fs-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='row'>
                                <div className='col-lg-8 mb-3'>
                                    <SkladisteRepromaterijalaDropdown
                                        value={skladiste}
                                        onChange={(name, value) => setSkladiste(value)}
                                        selectName='Skladiste'
                                    />
                                </div>
                                <div className='col-lg-4'>
                                    <button
                                        className='btn btn-block btn-primary'
                                        onClick={onClickSkladisteUpdate}
                                        disabled={!rowSelection.length || !skladiste || loading}
                                    >
                                        <i className="bi bi-check fs-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='row'>
                                <div className='col-lg-6 mb-2'>
                                    <LokacijeRepromaterijalaDropdown
                                        value={lokacija}
                                        onChange={(name, value) => setLokacija(value)}
                                        selectName='Lokacija'
                                    />
                                </div>
                                <div className='col-lg-4'>
                                    <select
                                        onChange={(e) => setLokacija2(e.target.value)}
                                        className='form-control form-control-lg form-control-solid mb-3'
                                    >
                                        <option value=''>Red</option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                        <option value='3'>3</option>
                                    </select>
                                </div>
                                <div className='col-lg-2 fv-row'>
                                    <button
                                        className='btn btn-block btn-primary'
                                        onClick={onClickLokacijaeUpdate}
                                        disabled={!rowSelection.length || !lokacija || !lokacija2 || loading}
                                    >
                                        <i className="bi bi-check fs-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mb-5'>
                <div
                    className='accordion-header py-3 d-flex collapsed'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_2_item_1'
                >
          <span className='accordion-icon'>
            <KTSVG className='svg-icon svg-icon-4' path='/media/icons/duotune/arrows/arr064.svg'/>
          </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>FILTERI TABELE</h3>
                </div>
                <div
                    id='kt_accordion_2_item_1'
                    className='fs-6 collapse ps-10'
                    data-bs-parent='#kt_accordion_2'
                >
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='row mt-6'>
                            <div className='col-lg-10'>
                                <div className='row'>
                                    <div className='col-lg-3 mb-3'>
                                        <input
                                            type='text'
                                            placeholder='Sirina'
                                            {...formik.getFieldProps('ReprMatSirina')}
                                            className={clsx('form-control form-control-lg form-control-solid')}
                                        />
                                    </div>
                                    <div className='col-lg-3 mb-3'>
                                        <input
                                            type='text'
                                            placeholder='Debljina'
                                            {...formik.getFieldProps('ReprMatDebljina')}
                                            className={clsx('form-control form-control-lg form-control-solid')}
                                        />
                                    </div>
                                    <div className='col-lg-3 mb-3'>
                                        <input
                                            type='text'
                                            placeholder='Broj Rolne'
                                            {...formik.getFieldProps('ReprMatId')}
                                            className={clsx('form-control form-control-lg form-control-solid')}
                                        />
                                    </div>
                                    <div className='col-lg-3 mb-3'>
                                        <KategorijaRepromaterijalaDropdown
                                            value={formik.values.ReprMatKategorija}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatKategorija', value)}
                                            selectName='ReprMatKategorija'
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <KodBojeRepromaterijalaDropdown
                                            value={formik.values.ReprMatKodBoje}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatKodBoje', value)}
                                            selectName='ReprMatKodBoje'
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <VrstaMaterijalaDropdown
                                            value={formik.values.ReprMatMaterijal}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatMaterijal', value)}
                                            selectName='ReprMatMaterijal'
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <KvalitetMaterijalaDropdown
                                            value={formik.values.ReprMatKvalitet}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatKvalitet', value)}
                                            selectName='ReprMatKvalitet'
                                        />
                                    </div>
                                    <div className='col-lg-3 mb-3'>
                                        <ProizvodjacPartnerDropdown
                                            value={formik.values.ReprMatProizvodjac}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatProizvodjac', value)}
                                            selectName='ReprMatProizvodjac'
                                        />
                                    </div>
                                    <div className='col-lg-3 mb-3'>
                                        <DobavljacPartnerDropdown
                                            value={formik.values.ReprMatDobavljac}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatDobavljac', value)}
                                            selectName='ReprMatDobavljac'
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <StatusRolneDropdown
                                            value={formik.values.ReprMatStatus}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatStatus', value)}
                                            selectName='ReprMatStatus'
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <SkladisteRepromaterijalaDropdown
                                            value={formik.values.ReprMatSkladiste}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatSkladiste', value)}
                                            selectName='ReprMatSkladiste'
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <LokacijeRepromaterijalaDropdown
                                            value={formik.values.ReprMatLokacija}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatLokacija', value)}
                                            selectName='ReprMatLokacija'
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <BojaDropdown
                                            value={formik.values.ReprMatBojaLica}
                                            onChange={(name, value) => formik.setFieldValue('ReprMatBojaLica', value)}
                                            selectName='ReprMatBojaLica'
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <GarancijaRepromaterijalaDropdown
                                            value={formik.values.garancija}
                                            onChange={(name, value) => formik.setFieldValue('garancija', value)}
                                            selectName='garancija'
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <PovrsinskaStrukturaDropdown
                                            value={formik.values.strukturaA}
                                            onChange={(name, value) => formik.setFieldValue('strukturaA', value)}
                                            selectName='strukturaA'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-2 fv-row'>
                                <button
                                    type='submit'
                                    className='btn btn-block btn-primary'
                                    disabled={filterLoading}
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default GetFilterRepromaterijala
