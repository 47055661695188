import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_websoft/layout/core'
import ArtikliLista from './ArtikliLista'
import ArtikliDetails from './ArtikliDetails'
import PaleteUlaziLista from './PaleteUlaziLista'
import PaleteUlaziDetails from './PaleteUlaziDetails'
import PaleteIzlaziLista from './PaleteIzlaziLista'
import PaleteIzlaziDetails from './PaleteIzlaziDetails'
import PrometArtiklaLista from './PrometArtiklaLista'
import PrometArtiklaDetails from './PrometArtiklaDetails'
import AktivnePalete from './AktivnePalete'
import TreeNode from "./TreePage";
import TreePage from "./TreePage";
import KarticaArtikla from "./KarticaArtikla";
import Izvjestaji from "./Izvjestaji";
import Inventura from "./Izvjestaji/Inventura";

const ArtikliBreadCrumbs: Array<PageLink> = [
  {
    title: 'Artikli',
    path: '/Artikli/lista',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ArtikliRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='lista'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Lista Artikala</PageTitle>
              <ArtikliLista />
            </>
          }
        />
        <Route
          path='dodaj'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Dodaj Artikal</PageTitle>
              <ArtikliDetails />
            </>
          }
        />
        <Route
          path='izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Izmjena Artikla</PageTitle>
              <ArtikliDetails />
            </>
          }
        />
        <Route
          path='palete-ulazi'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Palete Ulazi</PageTitle>
              <PaleteUlaziLista />
            </>
          }
        />
        <Route
          path='palete-ulazi/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Dodaj Ulaz Palete</PageTitle>
              <PaleteUlaziDetails />
            </>
          }
        />
        <Route
          path='palete-ulazi/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Izmjena Ulaza Palete</PageTitle>
              <PaleteUlaziDetails />
            </>
          }
        />
        <Route
          path='palete-izlazi'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Palete Izlazi</PageTitle>
              <PaleteIzlaziLista />
            </>
          }
        />
        <Route
          path='palete-izlazi/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Dodaj Izlaz Palete</PageTitle>
              <PaleteIzlaziDetails />
            </>
          }
        />
        <Route
          path='palete-izlazi/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Izmjena Izlaza Palete</PageTitle>
              <PaleteIzlaziDetails />
            </>
          }
        />
        <Route
          path='promet'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Promet Artikala</PageTitle>
              <PrometArtiklaLista />
            </>
          }
        />
        <Route
          path='promet/dodaj'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Dodaj Promet Artikla</PageTitle>
              <PrometArtiklaDetails />
            </>
          }
        />
        <Route
          path='promet/izmjena/:id'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Izmjena Prometa Artikla</PageTitle>
              <PrometArtiklaDetails />
            </>
          }
        />
        <Route
          path='aktivne-palete'
          element={
            <>
              <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Aktivne Palete Artikala</PageTitle>
              <AktivnePalete />
            </>
          }
        />
          <Route
              path='stablo'
              element={
                  <>
                      <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Stablo artikala</PageTitle>
                      <TreePage />
                  </>
              }
          />
          <Route
              path='kartica-artikla'
              element={
                  <>
                      <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Kartica artikala</PageTitle>
                      <KarticaArtikla />
                  </>
              }
          />
          <Route
              path='izvjestaji'
              element={
                  <>
                      <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Izvještaji</PageTitle>
                      <Izvjestaji />
                  </>
              }
          />
          <Route
              path='izvjestaji/inventura'
              element={
                  <>
                      <PageTitle breadcrumbs={ArtikliBreadCrumbs}>Izvještaji</PageTitle>
                      <Inventura />
                  </>
              }
          />
        <Route index element={<Navigate to='/artikli/Lista' />} />
      </Route>
    </Routes>
  )
}

export default ArtikliRoutes
