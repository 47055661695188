import axios from 'axios'
import {UposlenikModel} from '../models/UposlenikModel'
import {getAuth} from '../modules/auth'
import {
  DimenzijeNalogaModel,
  PaketiNalogaModel,
  ParamsModelProizvodnja,
  ProizvodnjaModel,
  StanjeOtpremePaketaModel,
} from '../models/ProizvodnjaModel'
import {ZapisiProizvodnjeModel} from '../models/ZapisiProizvodnjeModel'

const API_URL = process.env.REACT_APP_API_URL
const BIN_PACKING_URL = `${API_URL}/paneli/utovariPaketeUKamion`
const API = `${API_URL}/proizvodnja`
const API_2 = `${API_URL}/paneli/proizvodnjaNaredniPetDana`

const auth: UposlenikModel = getAuth()!
const headers = {Accept: 'application/json', Authorization: auth?.access_token ?? ''}

export async function getProizvodnja(params: ParamsModelProizvodnja) {
  return await axios.get<Array<ProizvodnjaModel>>(API, {headers: headers, params: params})
}

export async function getProizvodnjaNaredniPetDana() {
  return await axios.get<Array<ProizvodnjaModel>>(API_2, {headers: headers})
}

export async function getProizvodnjaPlan() {
  return await axios.get<Array<ProizvodnjaModel>>(`${API_URL}/paneli/proizvodnjaPlan`, {
    headers: headers,
  })
}

export async function urgentniNaloziZaTransport() {
  return await axios.get<Array<ProizvodnjaModel>>(`${API_URL}/paneli/naloziZaUrgentanTransport?per_page=5`, {
    headers: headers,
  })
}

export async function urgentniNaloziZaTransportAll(params: ParamsModelProizvodnja) {
  return await axios.get<Array<ProizvodnjaModel>>(`${API_URL}/paneli/naloziZaUrgentanTransport`, {headers: headers, params: params})
}

export async function naloziUProizvodnji() {
  return await axios.get<Array<ZapisiProizvodnjeModel>>(`${API_URL}/paneli/naloziUProizvodnji`, {
    headers: headers,
  })
}

export async function sumaNalogaNaCekanjuIAktivni() {
  return await axios.get(`${API_URL}/paneli/sumaNalogaNaCekanjuIAktivni`, {
    headers: headers,
  })
}

export async function saveProizvodnja(Data: any) {
  try {
    const response = await axios.post(API, Data, {headers: headers})

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function fetchProizvodnja(Id: number): Promise<Partial<ProizvodnjaModel>> {
  try {
    const response = await axios.get(`${API}/${Id}`)
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateProizvodnja(id: number, Data: any) {
  try {
    const response = await axios.post(`${API}/${id}?_method=PATCH`, Data, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function deleteProizvodnja(Id: number) {
  try {
    const response = await axios.delete(`${API}/${Id}`, {headers: headers})
    if (response.data) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to delete.')
    }
  } catch (error) {
    console.error('Error deleting data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function getStanjeOtpremePaketa(stavka: number): Promise<StanjeOtpremePaketaModel> {
  try {
    const params = {stavka: stavka}
    const response = await axios.get(`${API_URL}/getStanjeOtpremePaketa`, {
      headers: headers,
      params: params,
    })
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function getPaketiNaloga(stavka: number): Promise<Array<PaketiNalogaModel>> {
  try {
    const params = {stavka: stavka}
    const response = await axios.get(`${API_URL}/getPaketiNaloga`, {
      headers: headers,
      params: params,
    })
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function getDimenzijeNaloga(stavka: number): Promise<Array<DimenzijeNalogaModel>> {
  try {
    const params = {stavka: stavka}
    const response = await axios.get(`${API_URL}/getDimenzijeNaloga`, {
      headers: headers,
      params: params,
    })
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateDimenzijeNaloga(Data: any) {
  try {
    const response = await axios.put(`${API_URL}/paneli/updateDimenzijeNaloga`, Data, {
      headers: headers,
    })
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to update.')
    }
  } catch (error: any) {
    console.error('Error Updating data:', error)
    throw new Error(error.response.data.message) // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function getKupciPregled(idKupca: any) {
  try {
    const params = {idKupca: idKupca}
    axios.defaults.timeout = 10000
    const response = await axios.get(`${API_URL}/paneli/kupciPregled`, {
      headers: headers,
      params: params,
    })
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function updateIzmjeniStatus(params: any) {
  try {
    const response = await axios.put(`${API_URL}/paneli/izmjeniStatus`, params, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function updateNalogVracen(params: any) {
  try {
    const response = await axios.put(`${API_URL}/paneli/nalogVracen`, params, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}
export async function updateArhivirajNalog(params: any) {
  try {
    const response = await axios.put(`${API_URL}/paneli/arhivirajNalog`, params, {headers: headers})
    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Updating data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function KreirajPakete(Data: any) {
  try {
    const response = await axios.post(`${API_URL}/panelPakovanje`, Data, {headers: headers})

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function getServisUtovarPaketa() {
  try {
    const response = await axios.get(`${API_URL}/paneli/servisUtovarPaketa`, {
      headers: headers,
    })
    if (response.data && response.data.error === 0) {
      return response.data.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      return new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function utovariPakete(params: any) {
  try {
    const response = await axios.post(`${BIN_PACKING_URL}`, params)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function dnevniIzvjestajProizvodnja(Data: any) {
  try {
    const response = await axios.post(`${API_URL}/paneli/dnevniIzvjestajProizvodnja`, Data, {
      headers: headers,
    })

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export async function dnevniIzvjestajOtprema(Data: any) {
  try {
    const response = await axios.post(`${API_URL}/paneli/dnevniIzvjestajOtprema`, Data, {
      headers: headers,
    })

    if (response.data.error === 0) {
      return response.data
    } else {
      // Handle any other type of response structure or errors returned from the server here
      throw new Error(response.data.message || 'Failed to fetch.')
    }
  } catch (error) {
    console.error('Error Saving data:', error)
    throw error // Re-throw the error so it can be handled by the caller if necessary
  }
}

export interface UposlenikResponse {
  error: number;
  success: boolean;
  message: string;
  data: {
    zavrseniNalozi: number;
    otvoreniNalozi: number;
    isporuceno: number;
    isporucenoZadnjaSedmica
  };
}

export async function sedmicniIzvjestajUposlenika(data: Record<string, any>): Promise<UposlenikResponse> {
  try {
    const response = await axios.get<UposlenikResponse>(`${API_URL}/paneli/ucinkovitostUposlenikaSedmica`, {
      params: data, // Send data as query parameters
      headers: headers,
    });

    if (response.data.error === 0) {
      return response.data;
    } else {
      // Handle API error responses gracefully
      throw new Error(response.data.message || 'Failed to fetch data from the server.');
    }
  } catch (error: any) {
    // Log error details for debugging
    console.error('Error fetching data from API:', error.response?.data || error.message);

    // Re-throw the error to allow caller-specific error handling
    throw new Error(error.message || 'An unknown error occurred while fetching data.');
  }
}