import {ToastContainer} from "react-toastify";
import TitleComponent from "../../components/TitleComponent/TitleComponent";
import {Link} from "react-router-dom";
import {KTIcon} from "../../../_websoft/helpers";
import React from "react";

const Izvjestaji:React.FC = () => {
    return (
        <div className='card mb-5 mb-xl-10'>
            <ToastContainer/>
            <div className='card-header border-0 cursor-pointer' role='button'>
                <div className='card-title m-0'>
                    <TitleComponent name='Izvještaji'/>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <div className='card-body border-top p-9'>
                    <div className='row'>
                        <div className='col-lg-4 col-sm-12 p-3 mb-6'>
                            <Link to='inventura'>
                                <button type='button' className='btn btn-primary'>
                                    <KTIcon iconName='plus' className='fs-2'/>
                                    Inventura
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default Izvjestaji;
