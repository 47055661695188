import React, { useEffect, useState } from "react";
import { KTCard, KTCardBody } from "../../../../_websoft/helpers";
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ArtikliFilteri from "../../../components/ArtikliFilteri/ArtikliFilteri";
import TableSearch from "../../../components/TableSearch/TableSearch";
import ExportButton from "../../../components/TableExportToExcel/ExportButton";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import TablePagination from "../../../components/TablePagination/TablePagination";
import {
    ArtikliModel,
    ParamsModelArtikli,
} from "../../../models/ArtikliModel";
import { PaginationModel } from "../../../models/PaginationModel";
import {
    getArtikliStanjeInventura,
} from "../../../api";
import notifyToast from "../../../components/Toast/Toast";

const Inventura: React.FC = () => {
    const [data, setData] = useState<ArtikliModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [params, setParams] = useState<ParamsModelArtikli>({
        per_page: 10,
        page: 1,
        aktivan: 1,
    });
    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [suma, setSuma] = useState(0);

    // Fetch and update page data
    const updatePage = () => {
        setLoading(true);
        getArtikliStanjeInventura(params)
            .then((response) => {
                const data = response.data['data'];
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setData(data);
                console.log(data, 'data')
                setPagination({
                    curPage: response.data['current_page'],
                    totalPage,
                    perPage: params.per_page,
                });
            })
            .catch((error) => {
                console.error("Error: ", error);
                notifyToast(false, error.message || "An error occurred.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        updatePage();
    }, [params]);

    // Sorting data
    useEffect(() => {
        if (sorting[0]?.id) {
            const { id } = sorting[0];
            setData((prevData) =>
                [...prevData].sort((a, b) =>
                    a[id] > b[id] ? 1 : a[id] < b[id] ? -1 : 0
                )
            );
        }
    }, [sorting]);

    const onFilter = (filter: Partial<ParamsModelArtikli>) => {
        setParams((prev) => ({
            ...prev,
            ...filter,
            page: 1,
        }));
    };

    const columns = [
        {
            id: "ArtSifra",
            header: "Art Sifra",
            accessorKey: "ArtSifra",
        },
        {
            id: "Naziv",
            header: "Naziv",
            accessorKey: "ArtNaziv",
        },
        {
            id: "ProtocniMagacin",
            header: "Protocni Magacin",
            accessorKey: "ArtStanjeSA",
        },
        {
            id: "SumaPalete",
            header: "Palete",
            accessorKey: "SumaPalete",
        },
        {
            id: "Total",
            header: "Total",
            accessorKey: "Total",
        },
    ];

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
            globalFilter,
        },
        onSortingChange: setSorting,
    });

    return (
        <KTCard>
            <ToastContainer />
            <KTCardBody className="py-4">
                <div className="col-4 alert alert-info">
                    Tabela prikazuje roditelje artikala.
                </div>
                <div className="separator mb-5"></div>
                <div className="d-flex justify-content-between">
                    <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    <ExportButton table={table} filename={"artikli"}/>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className="table-responsive mt-3">
                        <table className="table align-middle table-bordered gy-5 no-footer">
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className="bg-dark">
                                    {headerGroup.headers.map((header) => (
                                        <th className="text-white"
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody>
                            {table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={columns.length}>No matching records found</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, perPage) =>
                        setParams({...params, page, per_page: perPage})
                    }
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    );
};

export default Inventura;
