/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {ParamsModelProizvodnja, ProizvodnjaModel} from '../../models/ProizvodnjaModel'
import {PaginationModel} from '../../models/PaginationModel'
import {
    SortingState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import {
    deleteProizvodnja,
    dnevniIzvjestajOtprema,
    getProizvodnja,
    sedmicniIzvjestajUposlenika
} from '../../api/proizvodnja'
import notifyToast from '../../components/Toast/Toast'
import {Link, useNavigate} from 'react-router-dom'
import {KTCard, KTCardBody, KTIcon} from '../../../_websoft/helpers'
import {ToastContainer} from 'react-toastify'
import TableSearch from '../../components/TableSearch/TableSearch'
import ExportButton from '../../components/TableExportToExcel/ExportButton'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import TablePagination from '../../components/TablePagination/TablePagination'
import {TableActionButton} from '../../components/TableActionBtn/TableActionButton'
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox/IndeterminateCheckbox'
import moments from 'moment'
import PakModal from '../../components/PakModal/PakModal'
import DimenzijeModal from '../../components/DimenzijeModal/DimenzijeModal'
import ProizodnjaFilteri from './components/ProizodnjaFilteri/ProizodnjaFilteri'
import ProizodnjaIzmjena from './components/ProizodnjaIzmjena/ProizodnjaIzmjena'
import {Modal, ModalHeader} from 'react-bootstrap'
import KreirajPaketeModal from './components/KreirajPaketeModal/KreirajPaketeModal'

const Proizodnja = () => {
    const [data, setData] = useState<ProizvodnjaModel[]>([])
    const [loading, setLoading] = useState(true)
    const [pagination, setpagination] = useState<PaginationModel>({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [stavkaId, setstavkaId] = useState<number>()
    const [isModalOpen, setisModalOpen] = useState(false)
    const [isDimenzijeModalOpen, setisDimenzijeModalOpen] = useState(false)
    const [params, setparams] = useState<ParamsModelProizvodnja>({per_page: 10, page: 1})
    const [isSumaOpen, setisSumaOpen] = useState(false)
    const [suma, setSuma] = useState(0)
    const [otpremiti, setOtpremiti] = useState(0)
    const [isKreirajPaketeOpen, setisKreirajPaketeOpen] = useState(false)

    const [sorting, setSorting] = useState<SortingState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [rowSelection, setRowSelection] = useState({})

    const [zavrseniNalozi, setzavrseniNalozi] = useState(0)
    const [otvoreniNalozi, setotvoreniNalozi] = useState(0)
    const [isporucenoZadnjaSedmica, setisporucenoZadnjaSedmica] = useState(0)
    const [isporuceno, setisporuceno] = useState(0)
    const [korisnik, setkorisnik] = useState(0)

    const formatter = new Intl.NumberFormat('bs-BA', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    // Set cell color according to condtions.
    const getCellColor = (cell, row) => {
        // Color for Tr.roba
        if (cell.column.id === 'Status')
            return row.original.statusPro === 'Planirano' ? '#FFFF00' : '#008000'
        // Color default
        return '#FFFFFF'
    }

    const onPakClick = (id) => {
        setisModalOpen(true)
        setstavkaId(id)
    }
    const onDimenzijeClick = (id) => {
        setisDimenzijeModalOpen(true)
        setstavkaId(id)
    }

    // Suma.
    const handleSuma = () => {
        let suma = 0; // For "Kolcina"
        let otpremiti = 0; // For "Za otpremu"

        // Iterate over all rows
        table.getRowModel().rows.forEach((row) => {
            // Calculate "Kolcina"
            const cur_val = row
                .getVisibleCells()
                .find((cell) => cell.column.id === 'Kolcina')
                ?.getValue();

            // Ensure cur_val is a string or convert to a string for parseFloat
            suma += parseFloat(String(cur_val || '0'));

            // Calculate "Za otpremu"
            const data = row.original.panel_pakovanja2;
            if (data && Array.isArray(data)) {
                const totalArea = data.reduce((sum, panel) => {
                    const duzina = parseFloat(String(panel.duzina || '0'));
                    const sirina = parseFloat(String(panel.sirinaPaketa || '0'));
                    const brPanela = parseFloat(String(panel.brPanela || '0'));

                    return sum + (duzina / 1000) * (sirina / 1000) * brPanela;
                }, 0);

                otpremiti += totalArea; // Add to otpremiti
            }
        });

        // Round to 2 decimal places
        const formatter = new Intl.NumberFormat('bs-BA', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        const formattedSuma = formatter.format(suma);
        const formattedOtpremiti = formatter.format(otpremiti);

        // Update state with the original numeric values
        setSuma(suma);
        setOtpremiti(otpremiti);


    };

    const updatePage = () => {
        setLoading(true)
        getProizvodnja(params)
            .then((response) => {
                const data = response.data['data']
                setData(data)
                const totalPage = Math.floor(response.data['total'] / response.data['per_page'] + 1)
                setpagination({
                    curPage: response.data['current_page'],
                    totalPage: totalPage,
                    perPage: params.per_page,
                })
            })
            .catch((error) => {
                console.error('Error: ', error)
                notifyToast(false, error)
            })
            .finally(() => {
                setLoading(false)
                setRowSelection({})
            })
    }

    useEffect(() => {
        updatePage()
    }, [params])

    useEffect(() => {
        const {id} = sorting[0] ?? ''
        data.sort((a, b) => a[id] - b[id])
    }, [sorting])

    const onFilter = async (filter: Partial<ParamsModelProizvodnja>) => {
        setparams({
            per_page: pagination.perPage,
            page: 1,
            statusPro: filter.statusPro,
            tipPanela: filter.tipPanela,
            kreiraoKorisnik: filter.kreiraoKorisnik,
            idKupac: filter.idKupac,
            brojNalog: filter.brojNalog,
        });

        // Check if kreiraoKorisnik is selected
        if (filter.kreiraoKorisnik) {
            setkorisnik(filter.kreiraoKorisnik)
            try {
                const response = await sedmicniIzvjestajUposlenika({ uposlenik: filter.kreiraoKorisnik });
                console.log(response, 'sedmicniIzvjestajUposlenika');
                if (response) {
                    // Update the states with the data from the response
                    setzavrseniNalozi(response.data.zavrseniNalozi || 0);
                    setotvoreniNalozi(response.data.otvoreniNalozi || 0);
                    setisporuceno(response.data.isporuceno || 0);
                    setisporucenoZadnjaSedmica(response.data.isporucenoZadnjaSedmica || 0);

                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle errors here, maybe show a toast or alert
            }
        }

    };

    const columnHelper = createColumnHelper<ProizvodnjaModel>()
    const columns = [
        {
            id: 'id',
            header: ({table}) => (
                <div className='row p-2'>
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({row, getValue}) => (
                <div className='col'>
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),
        },
        {
            id: 'Nalog',
            header: 'Nalog',
            accessorFn: (row) => `${row.brojNaloga} ${row.brojPonude}`,
            cell: ({row}) => (
                <div>
                    <div>
                        N:{row.original.brojNaloga}({row.original.stavka})
                    </div>
                    <div>
                        Vr.n:
                        <KTIcon
                            iconName={row.original.nalogVracen === 0 ? 'cross' : 'check'}
                            className={row.original.nalogVracen === 0 ? 'text-danger fs-2' : 'text-success fs-2'}
                        />
                    </div>
                    <div>P:{row.original.brojPonude}</div>
                </div>
            ),
        },
        {
            id: 'Artikal',
            header: 'Artikal',
            accessorKey: 'artikal',
        },
        {
            id: 'Kupac',
            header: 'Kupac/kreirao',
            accessorFn: (row) => `${row.kupac} ${row.kreiraoKorisnik}`,
            cell: ({row}) => (
                <div>
                    {row.original.kupac}
                    <div style={{color: 'red'}}>({row.original.kreiraoKorisnik})</div>
                </div>
            ),
        },
        {
            id: 'Panel',
            header: 'Panel',
            accessorKey: 'tip_panela.Naziv',
        },
        {
            id: 'izolacija',
            header: 'Izolacija',
            accessorKey: 'izolacija_panela.naziv',
        },
        columnHelper.group({
            header: 'Gornji lim (lice)',
            // footer: props => props.column.id,
            columns: [
                columnHelper.accessor('obloga_gore.naziv', {
                    id: 'obloga_gore',
                    cell: (info) => info.getValue(),
                    header: () => <span>Obloga</span>,
                }),
                columnHelper.accessor('debljina_gore.debljina', {
                    id: 'debljina_gore',
                    cell: (info) => info.getValue(),
                    header: () => <span>Debljina</span>,
                }),
                columnHelper.accessor('boja_gore.BojaNaziv', {
                    id: 'boja_gore',
                    cell: (props) => {
                        const rolneG: string = props.row.original.rolneG || '';
                        const formattedRolneG = rolneG
                            .split('/') // Split the values by "/"
                            .reduce<string[][]>((acc, item, index) => {
                                const groupIndex = Math.floor(index / 2); // Group by 2
                                acc[groupIndex] = (acc[groupIndex] || []).concat(item); // Add item to the correct group
                                return acc;
                            }, [])
                            .map((group) => group.join('/')) // Join grouped values with "/"
                            .join('<br/>'); // Add line breaks between groups

                        return (
                            <div>
                                <span>{props.getValue()}</span><br/>
                                <div
                                    className="badge badge-success"
                                    dangerouslySetInnerHTML={{__html: formattedRolneG}}
                                />
                            </div>
                        );
                    },
                    header: () => <span>Boja</span>,
                }),
                columnHelper.accessor('folijaG', {
                    id: 'folijaG',
                    cell: (info) => info.getValue() === '1' ? 'Da' : 'Ne',
                    header: () => <span>Folija</span>,
                }),
            ],
        }),
        columnHelper.group({
            header: 'Donji lim (nalicje)',
            // footer: props => props.column.id,
            columns: [
                columnHelper.accessor('obloga_dole.naziv', {
                    id: 'obloga_dole',
                    cell: (info) => info.getValue(),
                    header: () => <span>Obloga</span>,
                }),
                columnHelper.accessor('debljina_dole.debljina', {
                    id: 'debljina_dole',
                    cell: (info) => info.getValue(),
                    header: () => <span>Debljina</span>,
                }),
                columnHelper.accessor('boja_dole.BojaNaziv', {
                    id: 'boja_dole',
                    cell: (props) => {
                        const rolneD: string = props.row.original.rolneD || '';
                        const formattedRolneD = rolneD
                            .split('/') // Split the values by "/"
                            .reduce<string[][]>((acc, item, index) => {
                                const groupIndex = Math.floor(index / 2); // Group by 2
                                acc[groupIndex] = (acc[groupIndex] || []).concat(item); // Add item to the correct group
                                return acc;
                            }, [])
                            .map((group) => group.join('/')) // Join grouped values with "/"
                            .join('<br/>'); // Add line breaks between groups

                        return (
                            <div>
                                <span>{props.getValue()}</span><br/>
                                <div
                                    className="badge badge-success"
                                    dangerouslySetInnerHTML={{__html: formattedRolneD}}
                                />
                            </div>
                        );
                    },
                    header: () => <span>Boja</span>,
                }),
                columnHelper.accessor('folijaD', {
                    id: 'folijaD',
                    cell: (info) => info.getValue() === '1' ? 'Da' : 'Ne',
                    header: () => <span>Folija</span>,
                }),
            ],
        }),
        {
            id: 'Kolcina',
            header: 'Količina [m2]',
            accessorKey: 'kolicina',
            cell: ({row}) => (
                <div>
                    {row.original.kolicina}
                    {row.original.kolicina !== row.original.kolicinaPaketa && (
                        <div className='text-danger'>Upozorenje</div>
                    )}
                </div>
            ),
        },
        {
            id: 'Otprema',
            header: 'Za otpremu [m2]',
            accessorKey: 'Za otpremu',
            cell: ({row}) => {
                const data = row.original.panel_pakovanja2; // Assuming this is your array of objects
                if (!data) return 'N/A';

                // Calculate total area for this row
                const totalArea = data.reduce((sum, panel) => {
                    const duzina = parseFloat(panel.duzina || 0); // Assuming 'visinaPaketa' is 'debljina'
                    const sirina = parseFloat(panel.sirinaPaketa || 0);
                    const brPanela = parseFloat(panel.brPanela || 0);

                    // Add the calculated area to the sum
                    return sum + (duzina / 1000) * (sirina / 1000) * brPanela;
                }, 0);

                return (
                    <div>
                        {totalArea.toFixed(2)} m²
                        {row.original.kolicina !== row.original.kolicinaPaketa && (
                            <div className='text-danger'>Upozorenje</div>
                        )}
                    </div>
                );
            },
        },
        {
            id: 'Brtvena',
            header: 'Brtvena traka',
            accessorKey: 'brtvenaTraka',
            cell: ({row}: { row: any }) =>
                row.original.brtvenaTraka === '1' ? "Da" : "Ne"
        },
        {
            id: 'Status',
            header: 'Status - Proizvodnje',
            accessorFn: (row) => `${row.statusPro} | ${moments(row.statusProDatum).format('DD-MM-YYYY')}`,
        },
        {
            id: 'isporuka',
            header: 'Isporuka',
            accessorFn: (row) => `${moments(row.isporukaFMK).format('DD-MM-YYYY')}`,
        },
        {
            id: 'pak',
            header: 'Pak',
            cell: ({row}) => (
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        onPakClick(row.original.id)
                    }}
                >
                    Pak
                </button>
            ),
        },
        {
            id: 'Dim',
            header: 'Dim',
            cell: ({row}) => (
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        onDimenzijeClick(row.original.id)
                    }}
                >
                    Dim
                </button>
            ),
        },
        {
            header: 'Akcija',
            accessorKey: 'Akcija',
            cell: ({row}) => (
                <TableActionButton
                    onDelete={() => onDelete(row.original.id)}
                    onEdit={() => {
                        onEdit(row.original.id)
                    }}
                />
            ),
        },
    ]
    const table = useReactTable({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting: sorting,
            globalFilter: globalFilter,
            rowSelection: rowSelection,
        },
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
    })
    const onDelete = (id) => {
        deleteProizvodnja(id)
            .then((response) => {
                notifyToast(true, response)
                setparams({per_page: pagination.perPage, page: pagination.curPage})
            })
            .catch((error) => {
                notifyToast(false, error.data['message'])
            })
    }
    const navigate = useNavigate()

    const onEdit = (rowData) => {
        navigate(`/paneli/izmjena/${rowData}`)
    }

    return (
        <KTCard>
            <ToastContainer/>
            <PakModal isModalOpen={isModalOpen} setisModalOpen={setisModalOpen} stavkaId={stavkaId}/>
            <DimenzijeModal
                isModalOpen={isDimenzijeModalOpen}
                setisModalOpen={setisDimenzijeModalOpen}
                stavkaId={stavkaId}
            />
            <KreirajPaketeModal
                isModalOpen={isKreirajPaketeOpen}
                setisModalOpen={setisKreirajPaketeOpen}
                idProizvodnje={table.getSelectedRowModel().rows}
            />
            <Modal show={isSumaOpen} onHide={() => setisSumaOpen(false)} size="lg">
                <ModalHeader closeButton={true} className='h3'>
                    <h3>Stanje lagera panela, korisnik: {korisnik}</h3>
                </ModalHeader>
                <div className="row p-5">
                    <h1>UKUPNO STANJE ZAVRŠENIH NALOGA</h1>
                    <div className="col-4">
                        <div className="card bg-primary mb-1">
                            <div className="card-body">
                                <h3>Količina završenih naloga: <hr/></h3>
                                <h3>{formatter.format(suma)} m²</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card bg-success mb-1">
                            <div className="card-body">
                                <h3>Otpremljeno: <hr/></h3>
                                <h3>{formatter.format(suma - otpremiti)} m²</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card bg-danger">
                            <div className="card-body">
                                <h3>Količina završenih naloga za otpremiti: <hr/></h3>
                                <h3>{formatter.format(otpremiti)} m²</h3>
                            </div>
                        </div>
                    </div>
                    <div className="separator mt-4 mb-3"></div>
                    <div className="col-12">
                        <h1>UKUPNO STANJE ZAVRŠENIH NALOGA (u zadnjih 7 dana)</h1>
                    </div>
                    <div className="row p-3">
                        <div className="col-4">
                            <div className="card bg-primary mb-1">
                                <div className="card-body">
                                    <h3>Količina završenih naloga: <hr/>{formatter.format(zavrseniNalozi)} m²</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card bg-success">
                                <div className="card-body">
                                    <h3>Otpremljeno: <hr/>{formatter.format(isporucenoZadnjaSedmica)} m²</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card bg-danger">
                                <div className="card-body">
                                    <h3>Količina završenih naloga za
                                        otpremiti: <hr/>{formatter.format(zavrseniNalozi - isporucenoZadnjaSedmica)} m²
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="separator mt-4 mb-3"></div>
                    <div className="col-12">
                        <h1>UKUPNO STANJE ZAVRŠENIH NALOGA (starijih od 7 dana)</h1>
                    </div>
                    <div className="row p-3">
                        <div className="col-4">
                            <div className="card bg-primary mb-1">
                                <div className="card-body">
                                    <h3>Količina završenih naloga: <hr/>{formatter.format(suma -zavrseniNalozi)} m²</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card bg-success">
                                <div className="card-body">
                                    <h3>Otpremljeno: <hr/>{formatter.format((suma - otpremiti)-isporucenoZadnjaSedmica)} m²</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card bg-danger">
                                <div className="card-body">
                                    <h3>Količina završenih naloga za
                                        otpremiti: <hr/>{formatter.format((suma -zavrseniNalozi) - ((suma - otpremiti)-isporucenoZadnjaSedmica))} m²
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <KTCardBody className='py-4'>
                <div className='col'>
                    <ProizodnjaIzmjena
                        rowSelection={table.getSelectedRowModel().rows}
                        updatePage={() => updatePage()}
                    />
                    <ProizodnjaFilteri onFilter={onFilter}/>
                </div>
                <div className='separator'></div>
                <div className='row mt-3 mb-3'>
                    <div className='col'>
                        <Link to='kupci-pregled'>
                            <button className='btn btn-primary me-3'>
                                <KTIcon iconName='like' className='fs-2'/>
                                Kupci pregled
                            </button>
                        </Link>
                        <Link to='/paneli/pregled-paketa'>
                            <button className='btn btn-success me-3'>
                                <KTIcon iconName='like' className='fs-2'/>
                                Pregled paketa
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='separator mt-2 mb-2'></div>
                <div className='d-flex justify-content-between'>
                    <div data-kt-user-table-toolbar='base'>
                        <TableSearch onSearch={(text) => setGlobalFilter(text)}/>
                    </div>
                    <div data-kt-user-table-toolbar='base'>
                        <button
                            className='btn btn-light-success me-3'
                            onClick={() => {
                                setisKreirajPaketeOpen(true)
                            }}
                            disabled={!table.getSelectedRowModel().rows.length}
                        >
                            <KTIcon iconName='truck' className='fs-2'/>
                            Kreiraj paket
                        </button>
                        <button
                            className='btn btn-light-primary me-3'
                            onClick={() => {
                                handleSuma()
                                setisSumaOpen(true)
                            }}
                        >
                            <KTIcon iconName='calculator' className='fs-2'/>
                            Suma
                        </button>
                        <ExportButton table={table} filename={'proizvodnja'}/>
                    </div>
                </div>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer' style={{fontSize: '11px'}}>
                            <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <span>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {/* @ts-ignore */}
                                                    {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                          </span>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
                                table.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                <TablePagination
                    updatePage={(page, per_page) => setparams({...params, page: page, per_page: per_page})}
                    pagination={pagination}
                />
            </KTCardBody>
        </KTCard>
    )
}

export default Proizodnja
