/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from 'react'
import {Modal, ModalHeader} from 'react-bootstrap'
import {getFmkNalogaStavkaData, izlazStavkiFmkNaloga} from '../../../api/fmkNalozi'
import {FmkNalogiStavkeModel} from '../../../models/FmkNaloziModel'
import notifyToast from '../../../components/Toast/Toast'
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import LoadingComponent from '../../../components/LoadingComponent/LoadingComponent'
import TitleComponent from '../../../components/TitleComponent/TitleComponent'
import KcerkeSifreDropdown from './KcerkeSifreDropdown'
import IndeterminateCheckbox from '../../../components/IndeterminateCheckbox/IndeterminateCheckbox'

const FmkNaloziModal = ({isModalOpen, setisModalOpen, selectedId}) => {
    const nalogId = useMemo(() => selectedId, [selectedId])
    const [dataNaloga, setDataNaloga] = useState<FmkNalogiStavkeModel['nalog'][]>([])
    const [dataStavka, setDataStavka] = useState<FmkNalogiStavkeModel['stavka']>([])
    const [loading, setLoading] = useState(true)
    const [rowSelection, setRowSelection] = useState({})

    const handleInputChange = (id, field, value) => {
        const newData = dataStavka.map((row) =>
            row.Id === id ? { ...row, [field]: value } : row
        );
        setDataStavka(newData);
        console.log(newData, 'Updated data'); // Debugging
    };

    const updateData = () => {
        getFmkNalogaStavkaData({idNaloga: nalogId})
            .then((response) => {
                if (response) {
                    const naloga: any = []
                    naloga.push(response.nalog)
                    setDataNaloga(naloga)
                    setDataStavka(response.stavka)
                    setRowSelection({})
                }
            })
            .catch((error) => notifyToast(false, error.response.data.message))
            .finally(() => setLoading(false))
    }
    useEffect(() => {
        updateData()
    }, [nalogId])

    // Auto-select row when Artikal Websoft dropdown value changes
    useEffect(() => {
        const updatedSelection = {};
        dataStavka.forEach((row) => {
            if (row.ArtikalWebsoft) {
                updatedSelection[row.IdArtikla] = true;
            }
        });
        setRowSelection(updatedSelection);
    }, [dataStavka]);

    // Set cell color according to condtions.
    const getCellColor = (cell, row) => {
        // Color
        if (cell.column.id === 'Status') {
            return row.original.statusi.Boja
        }
        // Color default
        return '#FFFFFF'
    }

    // Rows where dropdown is displayed are valid rows for update.
    const [validRows, setvalidRows] = useState<any>([])

    // on Potvrdi
    const onClickPotvrdi = () => {
        console.log("Button clicked");
        setLoading(true)
     /*   const payload = validRows.map((row) => {
            const item = row.original;
            const dataSet = {
                Id: item.Id,
                Artikal: item.ArtikalWebsoft,
                VrstaDokumentaBroj: dataNaloga[0].BrojNaloga,
                JM: item.IdJedMjere,
                Izlaz: item.Kolicina,
                Savijanje: item.Savijanje,
                Presanje: item.Presanje,
            };
            console.log(dataSet);  // Check here to ensure Savijanje and Presanje are included
            return dataSet;
        });*/
        const payload = dataStavka
            .filter((item) => item.Savijanje || item.Presanje || item.ArtikalWebsoft || item.BrKomada) // Filter rows based on conditions
            .map((item) => {
                return {
                    Id: item.Id,
                    Artikal: item.ArtikalWebsoft,
                    VrstaDokumentaBroj: dataNaloga[0].BrojNaloga,
                    JM: item.IdJedMjere,
                    Izlaz: item.Kolicina,
                    Savijanje: item.Savijanje,
                    Presanje: item.Presanje,
                    BrKomada: item.BrKomada,
                    StandardnaPozicija: item.StandardnaPozicija
                };
            });

        console.log(payload); // Check here to ensure only filtered items are included


        console.log(payload); // Check here to ensure all items are included and fields are populated correctly


        // Update api.
        izlazStavkiFmkNaloga(payload)
            .then((response) => {
                notifyToast(true, response.message)
                updateData()
            })
            .catch((error) => notifyToast(false, error.response.data.message))
            .finally(() => setLoading(false))
    }
    const columns1 = [
        {
            id: 'Nalog br',
            header: 'Nalog br',
            accessorKey: 'BrojNaloga',
        },
        {
            id: 'Kupac',
            header: 'Kupac',
            accessorKey: 'Kupac',
        },

        {
            id: 'Kreirao',
            header: 'Kreirao',
            accessorKey: 'Korisnik',
        },
    ]
    const handleCheckboxChange = (id, field, checked) => {
        const newData = dataStavka.map((row) =>
            row.IdArtikla === id ? { ...row, [field]: checked ? 1 : 0 } : row
        );
        setDataStavka(newData);
    };

    const table1 = useReactTable({
        data: dataNaloga,
        columns: columns1,
        getCoreRowModel: getCoreRowModel(),
    })

    const columns2 = [
        {
            id: 'Id',
            header: 'Id',
            accessorKey: 'id',
            cell: ({ row }) => <div style={{ width: '50px' }}>{row.original.Id}</div>,
        },
        {
            id: 'Naziv',
            header: 'Naziv',
            accessorKey: 'Naziv',
            cell: ({ row }) => <div style={{ width: '200px' }}>( {row.original.IdArtikla} ) {row.original.Naziv}</div>,
        },

        {
            id: 'Mjera',
            header: 'Mjera',
            accessorKey: 'IdJedMjere',
            cell: ({ row }) => <div style={{ width: '50px' }}>{row.original.IdJedMjere}</div>,
        },
        {
            id: 'Kolicina',
            header: 'Količina',
            accessorKey: 'Kolicina',
            cell: ({ row }) => <div style={{ width: '60px' }}>{row.original.Kolicina}</div>,
        },
        {
            id: 'Status',
            header: 'Status',
            accessorKey: 'statusi.Naziv',
        },
        {
            id: 'Savijanje',
            header: 'Savijanje',
            cell: ({ row }) => {
                const naziv = row.original.Naziv || '';
                return naziv.includes('Opšavi') ? (
                    <input
                        type="text"
                        className="form-control text-success"
                        style={{ width: '80px', borderColor: 'green' }}
                        value={row.original.Savijanje || ''} // Controlled input
                        onChange={(e) =>
                            handleInputChange(row.original.Id, 'Savijanje', e.target.value)
                        }
                    />
                ) : (
                    <span>---------</span>
                );
            },
        },
        {
            id: 'Presanje',
            header: 'Presanje',
            cell: ({ row }) => {
                const naziv = row.original.Naziv || '';
                return naziv.includes('Opšavi') ? (
                    <input
                        type="number"
                        className="form-control"
                        style={{ width: '80px', borderColor: 'blue' }}
                        value={row.original.Presanje || ''} // Controlled input
                        onChange={(e) =>
                            handleInputChange(row.original.Id, 'Presanje', e.target.value)
                        }
                    />
                ) : (
                    <span>---------</span>
                );
            },
        },
        {
            id: 'BrKomada',
            header: 'Br Komada',
            cell: ({ row }) => {
                const naziv = row.original.Naziv || '';
                return naziv.includes('Opšavi') ? (
                    <input
                        type="number"
                        className="form-control"
                        style={{ width: '80px', borderColor: 'blue' }}
                        value={row.original.BrKomada || ''} // Controlled input
                        onChange={(e) =>
                            handleInputChange(row.original.Id, 'BrKomada', e.target.value)
                        }
                    />
                ) : (
                    <span>---------</span>
                );
            },
        },
        {
            id: 'StandardnaPozicija',
            header: 'Stan Poz',
            cell: ({ row }) => {
                const naziv = row.original.Naziv || '';
                return naziv.includes('Opšavi') ? (
                    <input
                        type="checkbox"
                        className="form-check-input cursor-pointer"
                        checked={!!row.original.StandardnaPozicija} // Ensure it's a boolean
                        onChange={(e) =>
                            handleInputChange(
                                row.original.Id,
                                'StandardnaPozicija',
                                e.target.checked // Pass boolean directly
                            )
                        }
                    />
                ) : (
                    <span>---</span>
                );
            },
        },
        {
            id: 'ArtikalWebsoft',
            header: 'Artikal Websoft',
            cell: TableCell,
        },
    ]
    const table2 = useReactTable({
        data: dataStavka,
        columns: columns2,
        getCoreRowModel: getCoreRowModel(),
        state: {
            rowSelection: rowSelection,
        },
        meta: {
            updateData: (rowIndex: number, columnId: string, value: string) => {
                setDataStavka((old) =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex],
                                [columnId]: value,
                            }
                        }
                        return row
                    })
                )
            },
        },
        onRowSelectionChange: setRowSelection,
        enableRowSelection: true,
    })

    useEffect(() => {
        const rows = table2.getSelectedRowModel().rows.filter(
            (row) =>
                row.original.Savijanje > 0 ||
                row.original.Presanje > 0
        );
        setvalidRows(rows);
        console.log("Valid rows:", rows); // Check if rows are populated
    }, [table2.getSelectedRowModel().rows, dataStavka]);

    return (
        <Modal
            className='modal-xl'
            show={isModalOpen && selectedId}
            onHide={() => setisModalOpen(false)}
        >
            <ModalHeader closeButton={true}>
                <h2 className='modal-title'>Stavke naloga</h2>
            </ModalHeader>
            <div className='p-5'>
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3'>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table1.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table1.getRowModel().rows && table1.getRowModel().rows.length > 0 ? (
                                table1.getRowModel().rows.map((row) => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
                {loading ? (
                    <LoadingComponent/>
                ) : (
                    <div className='table-responsive mt-3 '>
                        <table className='table align-middle table-bordered gy-5 no-footer'>
                            <thead>
                            {table2.getHeaderGroups().map((headerGroup) => (
                                <tr
                                    key={headerGroup.id}
                                    className='text-start fw-bolder fs-7 text-uppercase gs-0 bg-dark p-2'
                                >
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            className='text-white'
                                            key={header.id}
                                            onClick={header.column.getToggleSortingHandler()}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {/* @ts-ignore */}
                                            {{asc: '🔼', desc: '🔽'}[header.column.getIsSorted() ?? null]}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className='text-gray-600'>
                            {table2.getRowModel().rows && table2.getRowModel().rows.length > 0 ? (
                                table2.getRowModel().rows.map((row) => (
                                    <tr key={row.original.IdArtikla} data-key={row.original.Id}>
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id} style={{backgroundColor: getCellColor(cell, row)}}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            No matching records found
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={loading}
                                onClick={onClickPotvrdi}
                            >
                                {!loading ? 'Potvrdi' : <span className='indicator-progress'>Please wait...</span>}
                            </button>

                        </div>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default FmkNaloziModal

const TableCell = ({getValue, row, column, table}) => {
    const initialValue = getValue()
    const [value, setValue] = useState(initialValue)
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        table.options.meta?.updateData(row.index, column.id, value)
    }, [value])

    return (
        <KcerkeSifreDropdown
            sifra={row.original.IdArtikla}
            value={value}
            onChange={(name, value) => setValue(value)}
        />
    )
}
