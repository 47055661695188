/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMain() {
    const intl = useIntl()
    const {currentUser} = useAuth()

    // Array as per privilege
    const privilege = {
        0: [0],
        1: [0, 1],
        2: [0, 2],
        3: [0, 3],
    }
    return (
        <>
            {privilege[1].includes(currentUser!.Privilegija) && (
                <>
                    {' '}
                    <AsideMenuItem
                        to='/dashboard'
                        icon='ranking'
                        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                    />
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Websoft</span>
                        </div>
                    </div>
                    {/* SIFARNICI */}
                    <AsideMenuItemWithSub to='/sifarnici' title='Šifarnici' icon='element-1'>
                        <AsideMenuItem to='/sifarnici/boje/lista' title='RAL Boje' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/kodboje/lista' title='Kod Boje' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/sektori/lista' title='Sektori' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/odjeli/lista' title='Odjeli' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/skladista/lista' title='Skladišta' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/partneri/lista' title='Partneri' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/funkcije/lista' title='Funkcije' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/kvalitet-materijala/lista' title='Kvalitet Materijala' hasBullet={true} />
                        <AsideMenuItem to='/sifarnici/vrsta-materijala/lista' title='Vrsta Materijala' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/kategorije-vrsta/lista' title='Vrste Kategorija' hasBullet={true} />
                        <AsideMenuItem to='/sifarnici/kategorije/lista' title='Kategorije' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/jedinice-mjere/lista' title='Jedinice Mjere' hasBullet={true} />
                        <AsideMenuItem to='/sifarnici/vrste-dokumenata/lista' title='Vrste Dokumenata' hasBullet={true} />
                        <AsideMenuItem to='/sifarnici/pakovanja/lista' title='Pakovanja' hasBullet={true}/>
                        <AsideMenuItem to='/sifarnici/obuke/lista' title='Obuke' hasBullet={true}/>
                    </AsideMenuItemWithSub>

                    {/* ARTIKLI */}
                    <AsideMenuItemWithSub to='/artikli' title='Artikli' icon='element-4'>
                        <AsideMenuItem to='/artikli/lista' title='Lista artikala' hasBullet={true}/>
                        <AsideMenuItem to='/artikli/promet' title='Promet artikala' hasBullet={true}/>
                        <AsideMenuItem to='/artikli/aktivne-palete' title='Aktivne Palete' hasBullet={true}/>
                        <AsideMenuItem to='/artikli/palete-ulazi' title='Palete Ulazi' hasBullet={true}/>
                        <AsideMenuItem to='/artikli/palete-izlazi' title='Palete Izlazi' hasBullet={true}/>
                        <AsideMenuItem to='/artikli/kartica-artikla' title='Kartica Artikla' hasBullet={true}/>
                        <AsideMenuItem to='/artikli/izvjestaji' title='Izvještaji' hasBullet={true}/>
                        <AsideMenuItem to='/artikli/stablo' title='Stablo' hasBullet={true}/>
                    </AsideMenuItemWithSub>

                    {/* REPROMATERIJALI */}
                    <AsideMenuItemWithSub to='/repromaterijal' title='Repromaterijali' icon='element-11'>
                        <AsideMenuItem to='/repromaterijal/lista' title='Lista' hasBullet={true}/>
                        <AsideMenuItem  to='/repromaterijal/kartica-rolne' title='Kartica rolne' hasBullet={true} />
                        <AsideMenuItem to='/repromaterijal/dodaj' title='Dodaj rolnu' hasBullet={true}/>
                        <AsideMenuItem to='/repromaterijal/rezervacije' title='Rezervacije' hasBullet={true}/>
                        <AsideMenuItem to='/repromaterijal/reklamacije' title='Reklamacije' hasBullet={true}/>
                        <AsideMenuItem to='/repromaterijal/ulazi-repromaterijala' title='Ulazi repromaterijala' hasBullet={true}/>
                        <AsideMenuItem to='/repromaterijal/promet' title='Promet' hasBullet={true}/>
                        <AsideMenuItem to='/repromaterijal/greske' title='Greške rolni' hasBullet={true}/>
                        <AsideMenuItem to='/repromaterijal/izvjestaji' title='Izvještaji' hasBullet={true}/>
                        <hr style={{backgroundColor: 'white', color: 'white', border: 'none', height: '1px'}}/>
                        <AsideMenuItem to='/repromaterijal/brodovi' title='Brodovi' hasBullet={true}/>
                        <AsideMenuItem to='/repromaterijal/ugovori' title='Ugovori' hasBullet={true}/>
                        <AsideMenuItem to='/repromaterijal/stavke-ugovora' title='Stavke ugovora' hasBullet={true} />
                        <AsideMenuItem to='/repromaterijal/rolne-u-dolasku' title='Rolne u dolasku' hasBullet={true} />
                        <hr style={{backgroundColor: 'white', color: 'white', border: 'none', height: '1px'}}/>
                        <AsideMenuItem to='/repromaterijal/kontrola-rolni' title='Kontrola materijala' hasBullet={true} />
                    </AsideMenuItemWithSub>

                    {/* RS TABLE */}
                    <AsideMenuItemWithSub to='/table-rs' title='Table-RŠ' icon='element-3'>
                        <AsideMenuItem to='/table-rs/lista' title='Table' hasBullet={true}/>
                        <AsideMenuItem to='/table-rs/trebovanje' title='Trebovanje' hasBullet={true}/>
                        <AsideMenuItem to='/table-rs/palete' title='Palete' hasBullet={true}/>
                        <AsideMenuItem to='/table-rs/promet' title='Promet' hasBullet={true}/>
                        <AsideMenuItem to='/table-rs/izvjestaji' title='Izvještaji' hasBullet={true}/>
                    </AsideMenuItemWithSub>

                    {/* PANELI */}
                    <AsideMenuItemWithSub to='/paneli' title='Paneli' icon='element-5'>
                        <AsideMenuItemWithSub to='#' title='Šifarnici' hasBullet={true}>
                            <AsideMenuItem to='/paneli/sifre' title='Šifre' hasBullet={true}/>
                            <AsideMenuItem to='/paneli/debljine' title='Debljine' hasBullet={true}/>
                            <AsideMenuItem to='/paneli/obloge' title='Obloge' hasBullet={true}/>
                            <AsideMenuItem to='/paneli/sirine' title='Širine' hasBullet={true}/>
                            <AsideMenuItem to='/paneli/izolacije' title='Izolacije' hasBullet={true}/>
                            <AsideMenuItem to='/paneli/tip-panela' title='Tip Panela' hasBullet={true}/>
                        </AsideMenuItemWithSub>

                        <AsideMenuItem to='/paneli/lista' title='Proizvodnja' hasBullet={true}/>
                        <AsideMenuItem to='/paneli/pregled-paketa' title='Pregled paketa' hasBullet={true}/>
                        <AsideMenuItem to='/paneli/kreiraj-otpremnicu' title='Kreiraj otpremnicu' hasBullet={true} />
                        <AsideMenuItem to='/paneli/lezarina-paketa' title='Ležarina paketa' hasBullet={true}/>
                        <AsideMenuItem to='/paneli/urgentan-transport' title='Urgentan transport' hasBullet={true}/>
                        <AsideMenuItem to='/paneli/glavna-knjiga' title='Glavna knjiga' hasBullet={true}/>
                        <AsideMenuItem to='/paneli/druga-klasa-paketi' title='Druga klasa paketi' hasBullet={true} />
                        <AsideMenuItem to='/paneli/druga-klasa-dimenzije' title='Druga klasa dimenzije' hasBullet={true}/>
                        <AsideMenuItem to='/paneli/zapisi-proizvodnje' title='Zapisi Proizvodnje' hasBullet={true}/>
                        <AsideMenuItem to='/paneli/kontrola-kvaliteta' title='Kontrola kvaliteta' hasBullet={true} />
                        <AsideMenuItem to='/paneli/reklamacije' title='Reklamacije' hasBullet={true}/>
                        <AsideMenuItem to='/paneli/plan-proizvodnje' title='Plan Proizvodnje' hasBullet={true} />
                        <AsideMenuItem to='/paneli/arhiviraj-naloge' title='Arhiviraj naloge' hasBullet={true} />
                        <AsideMenuItem to='/paneli/izvjestaji' title='Izvještaji' hasBullet={true}/>
                        <AsideMenuItem to='/paneli/arhiva' title='Arhiva' hasBullet={true}/>
                    </AsideMenuItemWithSub>

                    {/* THERMOBOARD PLOCE */}
                    <AsideMenuItemWithSub to='/thermoboard' title='Thermoboard ploče' icon='element-6'>
                        <AsideMenuItem to='/thermoboard/lista' title='Nalozi' hasBullet={true}/>
                        <AsideMenuItem to='/thermoboard/paketi' title='Paketi' hasBullet={true}/>
                    </AsideMenuItemWithSub>

                    {/* FMK NALOZI PLOCE */}
                    <AsideMenuItemWithSub to='/fmk-nalozi' title='FMK nalozi' icon='element-7'>
                        <AsideMenuItem to='/fmk-nalozi/lista' title='FMK Nalozi Lista' hasBullet={true}/>
                        <AsideMenuItem to='/fmk-nalozi/stavke-u-proizvodnji' title='Stavke u proizvodnji' hasBullet={true} />
                    </AsideMenuItemWithSub>

                    <AsideMenuItemWithSub to='/galanterija' title='Galanterija' icon='element-8'>
                        <AsideMenuItem to='/galanterija/artikli-operacije' title='Artikli operacije' hasBullet={true} />
                        <AsideMenuItem to='/galanterija/zapisi-proizvodnje' title='Zapisi proizvodnje' hasBullet={true} />
                        <AsideMenuItem to='/galanterija/plavi-nalog' title='Artikli plavi nalog' hasBullet={true} />
                    </AsideMenuItemWithSub>

                    <AsideMenuItemWithSub to='/sredstva' title='Sredstva' icon='element-9'>
                        <AsideMenuItem to='/sredstva/lista' title='Lista sredstava' hasBullet={true}/>
                        <AsideMenuItem to='/sredstva/prijava-kvara' title='Prijave kvarova' hasBullet={true}/>
                        <AsideMenuItem to='/sredstva/intervencije-odrzavanja' title='Intervencije održavanja' hasBullet={true} />
                        <AsideMenuItem to='/sredstva/intervencije-izvrsioci' title='Intervencija Izvrsioci' hasBullet={true} />
                        <AsideMenuItem to='/sredstva/dozvole-atesti' title='Dokumenti / Dozvole / Atesti' hasBullet={true} />
                        <AsideMenuItem to='/sredstva/zahtjev-za-nabavu' title='Zahtjev za nabavu' hasBullet={true} />
                        <AsideMenuItem to='/sredstva/troskovi' title='Troškovi' hasBullet={true}/>
                        <AsideMenuItem to='/sredstva/zapisi-viljuskara' title='Zapisi viljuškara' hasBullet={true}/>
                    </AsideMenuItemWithSub>
                    <AsideMenuItemWithSub to='/uposlenici' title='Uposlenici' icon='element-10'>
                        <AsideMenuItem to='/uposlenici/lista' title='Lista' hasBullet={true}/>
                        <AsideMenuItem to='/uposlenici/dodaj' title='Dodaj' hasBullet={true}/>
                        <AsideMenuItem
                            to='/uposlenici/prijedlozi-obucavanja'
                            title='Prijedlozi obucavanja'
                            hasBullet={true}
                        />
                        <AsideMenuItem
                            to='/uposlenici/zapisnici-obucavanja'
                            title='Zapisnici obucavanja'
                            hasBullet={true}
                        />
                        <AsideMenuItem
                            to='/uposlenici/efikasnost-obucavanja'
                            title='Efikasnost obucavanja'
                            hasBullet={true}
                        />
                        <AsideMenuItem
                            to='/uposlenici/prijedlozi-za-poboljsanje'
                            title='Prijedlozi za poboljšanje'
                            hasBullet={true}
                        />
                        <AsideMenuItem to='/uposlenici/dosjei' title='Dosjei Lista' hasBullet={true}/>
                        <AsideMenuItem to='/uposlenici/izvjestaj' title='Izvjestaj' hasBullet={true}/>
                    </AsideMenuItemWithSub>

                    <AsideMenuItemWithSub to='/inventure' title='Inventure' icon='element-9'>
                        <AsideMenuItem to='/inventure/rolne' title='Rolne i trake' hasBullet={true}/>
                    </AsideMenuItemWithSub>

                    <AsideMenuItem to='/kreiraj-qr-code' title='QR Kod' icon='element-8'/>
                    <AsideMenuItem to='/razni-izvjestaji' title='Izvještaji' icon='element-9'/>
                    <AsideMenuItem to='/chat' title='Websoft AI chat' icon='element-7'/>
                    <AsideMenuItem to='/prodaja-najam/lista' title='Prodaja & Najam' icon='element-7'/>
                </>
            )}
            {privilege[2].includes(currentUser!.Privilegija) && (
                <>
                    {' '}
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Terminal</span>
                        </div>
                    </div>
                    <AsideMenuItem to='/terminali/pocetna' icon='document' title={'Dashboard'}/>
                    <div className='menu-item'>
                        <div className='menu-content'>
                            <div className='separator mx-1 my-4'></div>
                        </div>
                    </div>

                </>
            )}

            {privilege[3].includes(currentUser!.Privilegija) && (
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-2 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Partneri</span>
                        </div>
                    </div>
                    <AsideMenuItem to='/partneri/pocetna' icon='ranking' title={'ALT Beograd'}/>
                    <AsideMenuItemWithSub to='/partneri/paneli' title='Paneli' icon='element-10'>
                        <AsideMenuItem to='/partneri/paneli/proizodnja' title='Proizvodnja' hasBullet={true}/>
                        <AsideMenuItem
                            to='/partneri/paneli/pregled-paketa'
                            title='Pregled Paketa'
                            hasBullet={true}
                        />
                        <AsideMenuItem
                            to='/partneri/paneli/kupci-pregled'
                            title='Kreiraj utovar kamiona'
                            hasBullet={true}
                        />
                        <AsideMenuItem
                            to='/partneri/paneli/druga-klasa-paketi'
                            title='Druga klasa Paketi'
                            hasBullet={true}
                        />
                        <AsideMenuItem
                            to='/partneri/paneli/plan-proizvodnje'
                            title='Plan Proizvodnje'
                            hasBullet={true}
                        />
                    </AsideMenuItemWithSub>
                    <AsideMenuItemWithSub
                        to='/partneri/thermoboard'
                        title='Thermoboard ploče'
                        icon='element-6'
                    >
                        <AsideMenuItem to='/partneri/thermoboard/lista' title='Nalozi' hasBullet={true}/>
                        <AsideMenuItem to='/partneri/thermoboard/paketi' title='Paketi' hasBullet={true}/>
                    </AsideMenuItemWithSub>
                    <AsideMenuItemWithSub
                        to='/partneri/repromaterijali'
                        title='Repromaterijali'
                        icon='element-6'
                    >
                        <AsideMenuItem to='/partneri/repromaterijali/lista' title='Lista' hasBullet={true}/>
                        <AsideMenuItem
                            to='/partneri/repromaterijali/rezervacije'
                            title='Rezervacije'
                            hasBullet={true}
                        />
                    </AsideMenuItemWithSub>
                </>
            )}
        </>
    )
}
